import React from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    alpha,
    Typography,
    Box,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTheme } from "@mui/material/styles";
import {
    DISCORD,
    DISCORD_LINK,
    TWITTER,
    TWITTER_LINK,
} from "@js/components/constants/common";
import { StyledIcon } from "@js/components/Footer/styled";
import DiscordIcon from "@js/public/images/DiscordIcon.svg";

const ContactUsDialog = (props: any) => {
    const openDialog: boolean = props.openDialog;
    const setOpenDialog: React.Dispatch<React.SetStateAction<boolean>> =
        props.setOpenDialog;

    // Hook that returns theme object for use in function component
    const theme = useTheme();
    const defaultIconStyles = {
        height: "16px",
        marginRight: "14px",
        width: "20px",
    };

    // twitterIcon is a twitter icon react component
    const twitterIcon = <TwitterIcon sx={defaultIconStyles} />;

    // discordIcon is a discord icon styled component
    const discordIcon = (
        <StyledIcon
            sx={{
                ...defaultIconStyles,
                marginLeft: "0",
            }}
            src={DiscordIcon.src}
        />
    );

    /**
     * Display button with text and social media icon
     * @param {string} text Name the the social media
     * @param {string} href Link to the social platform
     * @param {jsx} icon social media icon
     * @returns button component consist of text, icon, and link
     */
    const renderSocialMediaButton = (
        text: string,
        href: string,
        icon: React.ReactNode
    ) => {
        return (
            <Button
                href={href}
                target="_blank"
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    margin: "0px 0px 48px 0px",
                    textAlign: "center",
                    textTransform: "none",
                    padding: ".5rem 1rem",
                    "&:hover": {
                        backgroundColor: alpha(theme.palette.primary.main, 0.8),
                    },
                }}
                startIcon={icon}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        lineHeight: "24px",
                    }}
                >
                    {text}
                </Typography>
            </Button>
        );
    };

    return (
        <Box>
            <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                }}
            >
                <DialogTitle
                    sx={{
                        fontSize: "32px",
                        fontWeight: "bold",
                        lineHeight: "133.4%",
                        marginTop: "47px",
                        textAlign: "center",
                    }}
                >
                    Get In Touch
                </DialogTitle>
                <DialogContent
                    sx={{
                        lineHeight: "19px",
                        margin: "16px 49px 24px 49px",
                        textAlign: "center",
                    }}
                >
                    Shoot us a DM on twitter or join our Discord server if you
                    have any questions about Triality or experience an issue
                    with our site.
                </DialogContent>
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="baseline"
                >
                    {renderSocialMediaButton(
                        TWITTER,
                        TWITTER_LINK,
                        twitterIcon
                    )}
                    {renderSocialMediaButton(
                        DISCORD,
                        DISCORD_LINK,
                        discordIcon
                    )}
                </Box>
            </Dialog>
        </Box>
    );
};

export default ContactUsDialog;
