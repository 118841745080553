import {
    Palette,
    PaletteColorOptions,
    PaletteOptions,
    SimplePaletteColorOptions,
    Theme,
    ThemeOptions,
} from "@mui/material";
import "codemirror/theme/shadowfox.css";
import "codemirror/theme/neat.css";

declare module "@mui/material/styles" {
    interface SimplePaletteColorOptions {}

    interface PaletteOptions {
        mentor?: SimplePaletteColorOptions;
        hyperlink?: SimplePaletteColorOptions;
        editor?: string;
        courseCard?: CustomSimplePaletteColorOptions;
        passingMark?: CustomSimplePaletteColorOptions;
    }

    interface ThemeOptions {
        palette?: PaletteOptions;
    }
}

export interface CustomSimplePaletteColorOptions
    extends SimplePaletteColorOptions {}

export interface CustomPaletteOptions extends PaletteOptions {
    mentor: CustomSimplePaletteColorOptions;
    hyperlink: CustomSimplePaletteColorOptions;
    courseCard: CustomSimplePaletteColorOptions;
    passingMark: CustomSimplePaletteColorOptions;
}

export interface TrialityThemeOptions extends ThemeOptions {
    palette: CustomPaletteOptions;
}

export type CustomThemeOptions = TrialityThemeOptions & Theme;

export const LIGHT_CRYPTO_PALETTE = {
    darkPurple: "#5034FF", // primary.main
    lightPurple: "#5034FF1A", // primary.light

    lightEmeraldGreen: "#4CAF50", // success.main

    rubyRed: "#D23744", // error.main

    misticPurple: "#FAFBFF", // background.paper

    black: "#072D4B", // text.primary
    lightBlackGray: "#00000099", // text.secondary
    paleBlackGray: "#0000004D", // text.tertiary

    lightViolet: "#ECECFF", // info.light
    violet: "#E7E7FF", // info.main
    strongViolet: "#D8D1FF",
    darkViolet: "#6866C2", // info.dark

    lightGray: "#C4C4C4", // grey[500]
    lessLightGray: "rgba(80, 52, 255, 0.1)", //grey[600] - this color is not grey; more light purple
    pacificBlue: "#366299",

    white: "#FFF", // secondary.main
    veryDarkBlue: "#0F0453", // secondary.light

    dividerGray: "#ECECEC", // divider

    curiousBlue: "#1877BC", // mentor

    azureBlue: "#0000ee", // hyperlink

    neat: "neat", // editor

    indigoBlue: "#130089", // course.main

    azureGreen: "#4CAF50", // passing mark
};

export const DARK_CRYPTO_PALETTE = {
    violetMirage: "#BFBDFF", // primary.main
    lightVioletMirage: "#BFBDFF1A", // primary.light

    lightEmeraldGreen: "#4CAF50", // success.main

    rubyRed: "#D23744", // error.main

    darkCharcoal: "#222222", // background.paper

    crystalSilver: "#E7E7E7", // text.primary
    lightCrystalSilver: "#FFFFFF99", // text.secondary
    paleCrystalSilver: "#FFFFFF4D", // text.tertiary

    lightLavender: "#BFBDFF1A", // info.light
    deepLavender: "#78748F", // info.main
    white: "#FFFFFF", // info.dark

    lightGray: "#FFFFFF99", // grey[500]
    lessLightGray: "#323138", //grey[600]
    veryDarkBlue: "#2B254E",

    black: "#000", // secondary.main
    darkGray: "#959799", // secondary.light

    dividerGray: "#505050", // divider

    winterWizard: "#9BFFF9", // mentor

    skyblue: "#8ab4f8", // hyperlink

    ambiance: "shadowfox", // editor

    paleBlack: "#2E2D2C", // secondary.dark (dark mode only)

    ceruleanBlue: "#6F6AFF", // course.main

    azureGreen: "#4CAF50", // passing mark
};
