import md5 from "md5";
import querystring from "query-string";
import { DEFAULT_AVATAR } from "../components/constants/common";
import Router from "next/router";
import { FormattedResponse } from "@js/components/Interfaces/interfaces";
import { NextApiRequest, NextApiResponse } from "next";
import { CairoRunResult } from "@js/components/EducationCourse/eduConfig";

/**
 * Get the search term from url
 * @returns string of previous search term if it exists
 */
export function getSearchTermFromUrl(): string {
    return _getParamValueFromUrl("search", "");
}

/**
 * Get the param from url
 * @returns string of param value if it exists
 */
export function getParamFromUrl(param: string): string {
    return _getParamValueFromUrl(param, "");
}

/**
 * @returns the page number from the url
 */
export function getPageFromUrl(): number {
    const pageParam = _getParamValueFromUrl("page", "");
    const page = parseInt(pageParam);
    if (Number.isNaN(page)) return 0;

    return page;
}

/**
 * @param defaultIfParamNotFound default to return if parameter is not found
 * @returns tab param value from the URL. Defaults to @param defaultIfParamNotFound if not present
 */
export function getTabFromUrl(defaultIfParamNotFound: string): string {
    return _getParamValueFromUrl("tab", defaultIfParamNotFound);
}

/**
 * @returns whether or not a search param is present in the url
 */
export function hasSearchParamInUrl(): boolean {
    return !!Router.query.search;
}

/**
 * Returns a paramater value from the URL
 * @param paramName parameter to get
 * @param defaultIfParamNotFound default value to return if the given parameter is not present
 */
function _getParamValueFromUrl(
    paramName: string,
    defaultIfParamNotFound: string
) {
    const paramValue = Router.query[paramName] as string;
    return paramValue ? paramValue : defaultIfParamNotFound;
}

/**
 * @param {string} account is referencing the wallet address of the account
 * @returns gravatar image request url
 */
export function getGravatarDefaultIcon(account: string) {
    /* Creating a query string from the object passed in. */
    const query = querystring.stringify({
        s: DEFAULT_AVATAR.size,
        r: DEFAULT_AVATAR.rating,
        d: DEFAULT_AVATAR.default,
    });

    /* Creating a hash of the account number. */
    const md5Hash = md5(account.trim().toLowerCase());
    const baseUrl = `${DEFAULT_AVATAR.protocol}${DEFAULT_AVATAR.domain}/avatar/`;
    const src = `${baseUrl}${md5Hash}?${query}`;

    return src;
}

/**
 * @param e an event object
 */
export const handleImageUrlError = (e: any) => {
    // prevent looping issue
    e.target.onerror = null;
    // TODO: fallback to a default image or .svg saved in our application
    e.target.src = "https://www.gravatar.com/avatar/";
};

/**
 * Reloads the page using the NextJS Router, which is not a hard page refresh.
 * Note, the page's state WILL NOT be reset by default. React does not unmount unless
 * the parent component has changed
 */
export const reloadPage = async () => {
    // reload the page
    await Router.replace(Router.asPath, Router.asPath, { scroll: false });
};

/**
 * Navigates to given path using the Next router
 * @param path - path to navigate to
 */
export const goTo = async (path: string) => {
    await Router.push(path);
};

/**
 * Fetch from the node server
 */
export async function fetchNodeServer(
    url: string,
    req: any
): Promise<CairoRunResult> {
    try {
        const nodeInfo = await fetch(`${process.env.NODE_HOST}${url}`, req);
        const crr: CairoRunResult = await nodeInfo.json();
        return crr;
    } catch (e: any) {
        console.error(e);
        return {
            success: false,
            output: {
                msg: e.message,
                lineNumErr: -1,
                colNumErr: -1,
                out: "",
            },
        };
    }
}

/**
 * Proxy a request to the node server
 */
export async function proxyToNodeServer(
    req: NextApiRequest,
    res: NextApiResponse
) {
    const url = `${process.env.NODE_HOST}${req.url}`;
    return await _proxyToInternalServer(req, res, url);
}

/**
 * Proxy a request to a server on the internal topology
 */
async function _proxyToInternalServer(
    req: NextApiRequest,
    res: NextApiResponse,
    url: string
) {
    try {
        const init: any = {
            method: req.method,
            headers: req.headers,
        };

        // if its a post, add on the body
        if (req.method === "POST") init.body = JSON.stringify(req.body);

        return await fetch(`${url}`, init)
            .then((response) => {
                // set headers
                for (const h of response.headers) {
                    const name = h[0];
                    const value = h[1];
                    // split the cookies into an array so the browser can process them correctly
                    // see: https://stackoverflow.com/a/66287304/18614102
                    if (name === "set-cookie")
                        res.setHeader(name, value.split(", "));
                    else res.setHeader(name, value);
                }
                return response.text();
            })
            .then((response) => res.send(response));
    } catch (e) {
        console.error(e);
        const fr: FormattedResponse = { status: "ERROR", data: e };
        res.status(500).json(fr);
    }
}
