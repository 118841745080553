export const IMAGE_EXTENSIONS: string[] = [
    "ase",
    "art",
    "bmp",
    "blp",
    "cd5",
    "cit",
    "cpt",
    "cr2",
    "cut",
    "dds",
    "dib",
    "djvu",
    "egt",
    "exif",
    "gif",
    "gpl",
    "grf",
    "icns",
    "ico",
    "iff",
    "jng",
    "jpeg",
    "jpg",
    "jfif",
    "jp2",
    "jps",
    "lbm",
    "max",
    "miff",
    "mng",
    "msp",
    "nitf",
    "ota",
    "pbm",
    "pc1",
    "pc2",
    "pc3",
    "pcf",
    "pcx",
    "pdn",
    "pgm",
    "PI1",
    "PI2",
    "PI3",
    "pict",
    "pct",
    "pnm",
    "pns",
    "ppm",
    "psb",
    "psd",
    "pdd",
    "psp",
    "px",
    "pxm",
    "pxr",
    "qfx",
    "raw",
    "rle",
    "sct",
    "sgi",
    "rgb",
    "int",
    "bw",
    "tga",
    "tiff",
    "tif",
    "vtf",
    "xbm",
    "xcf",
    "xpm",
    "3dv",
    "amf",
    "ai",
    "awg",
    "cgm",
    "cdr",
    "cmx",
    "dxf",
    "e2d",
    "egt",
    "eps",
    "fs",
    "gbr",
    "odg",
    "svg",
    "stl",
    "vrml",
    "x3d",
    "sxd",
    "v2d",
    "vnd",
    "wmf",
    "emf",
    "art",
    "xar",
    "png",
    "webp",
    "jxr",
    "hdp",
    "wdp",
    "cur",
    "ecw",
    "iff",
    "lbm",
    "liff",
    "nrrd",
    "pam",
    "pcx",
    "pgf",
    "sgi",
    "rgb",
    "rgba",
    "bw",
    "int",
    "inta",
    "sid",
    "ras",
    "sun",
    "tga",
];

export const STARKNET = "starknet";
export const METAMASK = "ethereum";

export const TITLE_CHAR_LIMIT = 255;
export const TEXT_EDITOR_DEFAULT_EMPTY_TEXT = [
    {
        type: "paragraph",
        children: [
            {
                text: "",
            },
        ],
    },
];
export const MAC = "mac";
export const HOTKEYS: any = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+shift+x": "strikethrough",
    "mod+shift+c": "code",
    "mod+shift+alt+c": "codeBlock",
};
export const MACOS: any = {
    bold: "CMD + B",
    italic: "CMD + I",
    underline: "CMD + U",
    strikethrough: "CMD + X",
    code: "CMD + Shift + C",
    codeBlock: "CMD + Option + Shift + C",
};
export const WINDOWS: any = {
    bold: "Ctrl + B",
    italic: "Ctrl + I",
    underline: "Ctrl + U",
    strikethrough: "Ctrl + X",
    code: "Ctrl + Shift + C",
    codeBlock: "Ctrl + Alt + Shift + C",
};

export const DEFAULT_AVATAR = {
    protocol: "https://",
    domain: "www.gravatar.com",
    size: 256,
    rating: "pg",
    default: "identicon",
};

export const CHALLENGED_SPAM: string = "challenged_spam";
export const CONFIRMED_NOT_SPAM: string = "not_spam";
export const MIN_TESTS = 1;
export const MAX_TESTS = 5;
export const CONNECT_WALLET_TO_POST_ANSWER_MSG =
    "Connect wallet to post an answer";
export const LOG_IN_TO_POST_ANSWER_MSG = "Log in to post an answer";
export const DEFAULT_CAIRO_EDITOR_SCRIPT = `%builtins output

// The triality function must return the expected output.
// You may call other functions from within this function.
func triality{output_ptr : felt*}(input : felt)  -> (res : felt) {
    let result = input;
    return (res=result);
}`;
export const HEADERS = ["Program Inputs", "Expected Outputs"];

export const TWITTER_LINK = "https://twitter.com/trialityorg";
export const DISCORD_LINK = "https://discord.com/invite/jtXmPJPaku";
export const TWITTER = "Twitter";
export const DISCORD = "Discord";

// Table headers for Challenges
export const CHALLENGE_QUESTION_TABLE_HEADERS = [
    {
        id: "title",
        label: "Title",
    },
    {
        id: "category",
        label: "Category",
    },
    {
        id: "solvedTestCases",
        label: "Solved Test Cases",
    },
    {
        id: "createdOn",
        label: "Created On",
    },
];
