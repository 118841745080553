import { Grid, Typography, Divider } from "@mui/material";
import { Box, SpacerBox, Anchor, StyledIcon } from "./styled";
import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTheme } from "@mui/material/styles";
import DiscordIcon from "@js/public/images/DiscordIcon.svg";
import {
    DISCORD,
    DISCORD_LINK,
    TWITTER,
    TWITTER_LINK,
} from "@js/components/constants/common";

function Footer() {
    // Hook that returns theme object for use in function component
    const theme = useTheme();

    // twitterIcon is a twitter icon react component
    const twitterIcon = (
        <TwitterIcon
            sx={{
                color:
                    theme.palette.mode === "dark"
                        ? theme.palette.common.white
                        : "#99A9B7",
                height: "16px",
                ml: "10px",
                width: "20px",
            }}
        />
    );

    // discordIcon is a discord icon styled component
    const discordIcon = <StyledIcon src={DiscordIcon.src} />;

    /**
     * @param {string} address the url to be redirected to
     * @param {string} description text describing url address
     * @param {boolean} icon optional parameter indicating if the icon is needed
     * returns styled Grid component
     */
    const GridItem = (
        address: string,
        description: string,
        icon?: React.ReactNode
    ) => (
        <Grid
            item
            pt={2}
            pb={2}
            pl={description.includes("Triality") ? 0 : 2}
            pr={2}
        >
            <Typography
                variant="body1"
                sx={{
                    bgcolor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    fontSize: "12px",
                    lineHeight: "22px",
                    textAlign: "center",
                }}
            >
                <Anchor href={address} target={icon ? "_blank" : ""}>
                    {description}
                    {icon}
                </Anchor>
            </Typography>
        </Grid>
    );

    return (
        <Box>
            <Divider />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <SpacerBox />
                {GridItem(TWITTER_LINK, TWITTER, twitterIcon)}
                {GridItem(DISCORD_LINK, DISCORD, discordIcon)}
            </Grid>
        </Box>
    );
}

export default Footer;
