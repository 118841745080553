import { LIGHT_CRYPTO_PALETTE } from "./Colors";
import { createTheme } from "@mui/material/styles";

const LightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            light: LIGHT_CRYPTO_PALETTE.lightPurple,
            main: LIGHT_CRYPTO_PALETTE.darkPurple,
        },
        success: {
            main: LIGHT_CRYPTO_PALETTE.lightEmeraldGreen,
        },
        error: {
            main: LIGHT_CRYPTO_PALETTE.rubyRed,
        },
        background: {
            paper: LIGHT_CRYPTO_PALETTE.misticPurple,
        },
        text: {
            primary: LIGHT_CRYPTO_PALETTE.black,
            secondary: LIGHT_CRYPTO_PALETTE.lightBlackGray,
            disabled: LIGHT_CRYPTO_PALETTE.paleBlackGray,
        },
        info: {
            light: LIGHT_CRYPTO_PALETTE.lightViolet,
            main: LIGHT_CRYPTO_PALETTE.violet,
            dark: LIGHT_CRYPTO_PALETTE.darkViolet,
        },
        grey: {
            500: LIGHT_CRYPTO_PALETTE.lightGray,
            600: LIGHT_CRYPTO_PALETTE.lessLightGray,
        },
        secondary: {
            light: LIGHT_CRYPTO_PALETTE.veryDarkBlue,
            main: LIGHT_CRYPTO_PALETTE.white,
        },
        divider: LIGHT_CRYPTO_PALETTE.dividerGray,
        mentor: {
            main: LIGHT_CRYPTO_PALETTE.curiousBlue,
        },
        hyperlink: {
            main: LIGHT_CRYPTO_PALETTE.azureBlue,
        },
        editor: LIGHT_CRYPTO_PALETTE.neat,
        courseCard: {
            main: LIGHT_CRYPTO_PALETTE.indigoBlue,
        },
        passingMark: {
            main: LIGHT_CRYPTO_PALETTE.azureGreen,
        },
    },
});

export default LightTheme;
