import { styled, Box as MuiBox } from "@mui/material";

export const Box = styled(MuiBox)(({ theme }) => {
    return {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    };
});

export const SpacerBox = styled(MuiBox)(({ theme }) => {
    return {
        flexGrow: 1,
    };
});

export const Anchor = styled("a")(({ theme }) => {
    return {
        color: theme.palette.text.primary,
        alignItems: "center",
        display: "flex",
    };
});

export const StyledIcon = styled("img")(({ theme }) => {
    return {
        objectFit: "contain",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontSize: "1.5rem",
        height: "1rem",
        marginLeft: ".85rem",
        width: "1.5rem",
    };
});
