import React, {
    useState,
    useEffect,
    useContext,
    SetStateAction,
    Dispatch,
    createContext,
} from "react";
import { CssBaseline, Backdrop, CircularProgress, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import DarkTheme from "@js/components/CustomColors/DarkTheme";
import LightTheme from "@js/components/CustomColors/LightTheme";
import { ethers } from "ethers";
import AppContainer from "@js/components/AppContainer";
import { signMessage, signMessageStarknet } from "../util/SignatureUtil";
import {
    BlockchainNetwork,
    ECDSASignature,
    TrialityApiResponse,
} from "@js/components/Interfaces/interfaces";
import { Response, isSuccess } from "@js/Response";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { User } from "@js/components/UserProfile/types";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import {
    LoginButton,
    LogoutButton,
    WalletButton,
    MenuItem,
    UserAddrDisplayNoIcon,
    UserAddrDisplayWithIcon,
    NetworkSelect,
    WalletImg,
} from "../components/styled";
import { connect, ConnectOptions, StarknetWindowObject } from "get-starknet";
import { getUserDisplayNameAbbrev } from "../util/addressUtils";
import { getCookieValue, hasCookie, setCookieValue } from "../util/cookieUtil";
import { useRouter } from "next/router";
import { AppProps } from "next/app";

declare const window: any;
let provider: ethers.providers.Web3Provider;
let signer: ethers.providers.JsonRpcSigner;
let starknet: StarknetWindowObject | null;

interface GlobalInterface {
    loadingSpinner: boolean;
    setLoadingSpinner: Dispatch<SetStateAction<boolean>>;
    globalUser: User;
    refreshGlobalUser: Function;
    loggedIn: boolean;
    globalQuestionSearch: string;
    setGlobalQuestionSearch: Dispatch<SetStateAction<string>>;
    login: any;
    connectWallet: Function;
    blockchainNetwork: BlockchainNetwork;
}
const GlobalContext = createContext<GlobalInterface>({} as GlobalInterface);

/*
 * Helper Context Function to force the pagelayout to re-render (i.e. if a profile picture changes in backend)
 */
export const userGlobalContext = () => {
    const context = useContext(GlobalContext);

    if (!context) {
        throw new Error("useData must be used within a <Parent />");
    }
    return context;
};

export default function App({ Component, pageProps }: AppProps) {
    const router = useRouter();
    // indicates whether page is currently on dark or light mode
    const [darkTheme, setDarkTheme] = useState<boolean>(true);
    const [user, setUser] = useState<User>({} as User);
    //Outstanding balance for the user based on questionTax or upvote love they have received
    const [balance, setBalance] = useState<number>(0);
    //Global spinner state variable
    const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);
    const [blockchainNetwork, setBlockchainNetwork] =
        useState<BlockchainNetwork>("");
    // wallet icon src (url or base64 data url)
    const [walletIconSrc, setWalletIconSrc] = useState<string>("");

    const [globalQuestionSearch, setGlobalQuestionSearch] = useState<string>(
        router.query.search as string
    );

    // initial page setup
    useEffect(() => {
        setDarkTheme(isInitialDarkTheme());
        setBlockchainNetwork(getInitialBlockchainNetworkState());
    }, []);

    /**
     * @returns if dark mode should be set on page load (from cookie)
     */
    function isInitialDarkTheme() {
        const themeCookie = getCookieValue("theme");
        if (themeCookie == "light") return false;

        return true;
    }

    /**
     * toggles between dark and light modes
     */
    const changeTheme = () => {
        const newDarkThemeVal = !darkTheme;
        setDarkTheme(newDarkThemeVal);
        setCookieValue("theme", newDarkThemeVal ? "dark" : "light");
    };
    /**
     * @returns initial blockchain network state value (from cookie)
     */
    function getInitialBlockchainNetworkState(): BlockchainNetwork {
        if (hasCookie("blockchain_network"))
            return getCookieValue("blockchain_network") as BlockchainNetwork;

        return "starknet";
    }

    /**
     * Handles when accounts in a wallet are changed
     * @param accountsConnected
     */
    function handleAccountsChanged(accountsConnected: Array<string>) {
        logout();
        if (blockchainNetwork === "starknet") {
            // connect to starknet but don't show the popup. Wallet is already connected, account was just switched
            connectStarknetWallet("neverAsk");
        } else if (blockchainNetwork === "ethereum") {
            if (accountsConnected.length === 0) setUser({} as User);
            else loadUser(accountsConnected[0]);
        }
    }

    /**
     * Setup for L1 ethereum (ethers.js)
     */
    async function ethereumSetup() {
        provider = new ethers.providers.Web3Provider(window.ethereum);
        signer = provider.getSigner();
        // check if a wallet is already connected
        const address = await signer.getAddress();
        setWalletIconSrc("");
        try {
            if (address) loadUser(address);
        } catch (e) {
            setUser({} as User);
        }
    }

    /**
     * Setup effect. Connects to wallets and contracts and performs setup
     */
    const [loggedIn, setLoggedIn] = useState<boolean>(checkLoggedIn());

    function checkLoggedIn() {
        // ethereum signatures set signature cookie
        // starknet signatures set r & s cookie
        return hasCookie("signature") || hasCookie("r");
    }

    useEffect(() => {
        async function setup() {
            // TODO: figure out how to make the accountsChanged handlers be registered only once
            // they keep getting registered every time the network changes
            if (blockchainNetwork === "starknet") {
                // try to connect to an approved wallet silently
                await connectStarknetWallet("neverAsk");
                starknet?.on("accountsChanged", handleAccountsChanged);
            } else if (blockchainNetwork === "ethereum") {
                window.ethereum.on("accountsChanged", handleAccountsChanged);
                await ethereumSetup();
            }
        }
        setup();
    }, [blockchainNetwork, starknet]);

    // When the url changes, we should clear global search if we are not on questions or if there is no search
    const location = router.asPath;
    useEffect(() => {
        if (router.pathname !== "/questions" || !router.query.search)
            setGlobalQuestionSearch("");
    }, [location]);

    async function loadUser(address: string) {
        const response = await fetch(`/api/getUser/${address}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const tar: TrialityApiResponse = await response.json();
        try {
            let user: User = tar.data;
            setUser(user || { address });
        } catch (e) {
            console.error(`Failed to load user: ${address}`, e);
            setUser({} as User);
        }
    }

    /**
     * Connect to an L1 ethereum wallet (metamask)
     */
    async function connectEthereumWallet() {
        await provider.send("eth_requestAccounts", []);
    }

    /**
     * Tries to connect to a starknet wallet (like ArgentX, Braavos, etc)
     * @param showPopup whether to show wallet popup when connecting
     */
    async function connectStarknetWallet(
        modalMode: ConnectOptions["modalMode"]
    ) {
        starknet = await connect({ modalMode: modalMode });
        if (starknet) {
            await starknet.enable();
            if (starknet.isConnected) {
                // make sure we're on the goerli testnet (this works for localhost:5050 as well)
                // see: https://github.com/0xs34n/starknet.js/blob/5374fdf676915d634775c7c072ea923ddaee2817/src/constants.ts#L13
                const SN_GOERLI = "0x534e5f474f45524c49";
                if (starknet.account.chainId !== SN_GOERLI) {
                    alert(
                        "Only Goerli Testnet is currently supported. Please switch networks"
                    );
                    return;
                }

                try {
                    loadUser(starknet.account.address);
                    setWalletIconSrc(starknet.icon);
                } catch (e) {
                    setUser({} as User);
                    setWalletIconSrc("");
                }
            }
        }
    }

    /**
     * Connects to a browser-based wallet according to the specified chain
     */
    async function connectWallet() {
        if (blockchainNetwork === "starknet") {
            await connectStarknetWallet("alwaysAsk");
        } else if (blockchainNetwork === "ethereum") {
            await connectEthereumWallet();
        }
    }

    function ConnectWalletButton() {
        return (
            <WalletButton
                variant="outlined"
                startIcon={
                    <AccountBalanceWalletOutlinedIcon
                        fontSize="small"
                        sx={{ color: "text.primary" }}
                    />
                }
                onClick={connectWallet}
            >
                Connect Wallet
            </WalletButton>
        );
    }

    /**
     * Get wallet Html depending on if user is logged in and if we are in V1
     * @returns jsx
     */
    function getWalletHtml() {
        if (user.address) {
            if (walletIconSrc) {
                return (
                    <>
                        <WalletImg src={walletIconSrc} />
                        <UserAddrDisplayWithIcon>
                            {getUserDisplayNameAbbrev(user)}
                        </UserAddrDisplayWithIcon>
                    </>
                );
            }
            return (
                <UserAddrDisplayNoIcon>
                    {getUserDisplayNameAbbrev(user)}
                </UserAddrDisplayNoIcon>
            );
        }

        return <ConnectWalletButton />;
    }

    const walletHtml = getWalletHtml();

    function getLoginLogoutButton() {
        if (!user.address) return "";

        if (loggedIn)
            return (
                <LogoutButton
                    variant="outlined"
                    startIcon={<LogoutOutlinedIcon fontSize="small" />}
                    onClick={logout}
                >
                    Logout
                </LogoutButton>
            );

        return (
            <LoginButton variant="outlined" onClick={login}>
                Login
            </LoginButton>
        );
    }

    const loginLogoutButton = getLoginLogoutButton();

    async function login() {
        let body;
        let message;
        let sig: ECDSASignature;
        const nonce = Math.floor(Math.random() * 100000);
        if (blockchainNetwork === "starknet") {
            message = `login_${nonce}`;
            let starknetSignature = await signMessageStarknet(
                message,
                starknet?.account
            );
            sig = {
                signature: "",
                r: starknetSignature[0],
                s: starknetSignature[1],
                v: -1,
            };
        } else {
            message = `Hello, please sign this message to login\nNonce: ${nonce}`;
            // uri encode the message b/c cookies can't have whitespace. The server will decode before verifying signature
            sig = await signMessage(message, signer);
        }

        body = {
            message: message,
            user: user,
            signature: sig,
            blockchain_network: blockchainNetwork,
        };

        const response = await fetch("/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        const tar: TrialityApiResponse = await response.json();

        try {
            let r: Response = tar.data;
            setLoggedIn(isSuccess(r));
            console.log(`Signature response: ${r}`);
        } catch (e) {
            console.error(`Failed to login: ${tar.err ? tar.err : ""}`, e);
            setLoggedIn(false);
        }
    }

    function logout() {
        function clearCookie(name: string) {
            document.cookie = `${name}=; Max-Age=0; path=/`;
        }

        ["message", "address", "signature", "r", "s", "blockchain_network"].map(
            (name) => clearCookie(name)
        );
        setLoggedIn(false);
    }

    /**
     * @returns the blockchain network selector component
     */
    function getNetworkSelector() {
        return (
            <NetworkSelect
                id="network"
                labelId="network-label"
                value={blockchainNetwork}
                size="small"
                onChange={(event) => {
                    setBlockchainNetwork(
                        event.target.value as BlockchainNetwork
                    );
                    logout();
                    setUser({} as User);
                }}
            >
                <MenuItem value={"starknet"}>Starknet</MenuItem>
                <MenuItem value={"ethereum"}>Metamask</MenuItem>
            </NetworkSelect>
        );
    }

    /**
     * Set the context with the variable names we want here
     * @returns - object of global context to be passed around
     */
    function setGlobalContext() {
        const globalUser = user;
        const refreshGlobalUser = () => loadUser(globalUser.address);
        return {
            loadingSpinner,
            setLoadingSpinner,
            globalUser,
            refreshGlobalUser,
            loggedIn,
            globalQuestionSearch,
            setGlobalQuestionSearch,
            login,
            connectWallet,
            blockchainNetwork,
        };
    }

    return (
        <ThemeProvider theme={darkTheme ? DarkTheme : LightTheme}>
            <React.Fragment>
                <CssBaseline />
                <GlobalContext.Provider value={setGlobalContext()}>
                    <AppContainer
                        walletButton={walletHtml}
                        loginLogoutButton={loginLogoutButton}
                        changeTheme={changeTheme}
                        networkSelector={getNetworkSelector()}
                    >
                        <Backdrop
                            open={loadingSpinner}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                zIndex: 9999,
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={3}
                            >
                                <Grid item>
                                    <div>
                                        <b>Verifying code</b>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        </Backdrop>
                        <Component key={router.asPath} {...pageProps} />
                    </AppContainer>
                </GlobalContext.Provider>
            </React.Fragment>
        </ThemeProvider>
    );
}
