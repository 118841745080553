import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    InputAdornment,
    IconButton,
    Input,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
    LIGHT_CRYPTO_PALETTE,
    DARK_CRYPTO_PALETTE,
} from "../../../../CustomColors/Colors";

export interface SearchDialogProps {
    // state to handle opening closing of dialog
    showDialog: boolean;
    //This is the value of the tab filter as stored by the parent, should only be used on first initialization
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
    // Search function to trigger
    search: any;
}

const SearchDialog = (props: SearchDialogProps) => {
    const { showDialog, setShowDialog, search } = props;

    // value of the search input
    const [searchValue, setSearchValue] = useState("");

    /**
     * Update search value
     * @param event - input change event
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    /**
     * trigger search functions with necessary parameters
     */
    const handleSearch = () => {
        search({ key: "Enter", target: { value: searchValue } });
    };

    /**
     * trigger search function when user hits Enter
     * @param event - key press event
     */
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={showDialog}
            onClose={() => {
                setSearchValue("");
                setShowDialog(false);
            }}
        >
            <DialogContent
                sx={{
                    display: "flex",
                    minHeight: "90px",
                    backgroundColor: LIGHT_CRYPTO_PALETTE.strongViolet,
                }}
            >
                <IconButton
                    onClick={() => setShowDialog(false)}
                    sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        color: LIGHT_CRYPTO_PALETTE.black,
                        "&:hover": {
                            backgroundColor: DARK_CRYPTO_PALETTE.violetMirage,
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Input
                    placeholder="Search..."
                    sx={{
                        borderBottom: `0.5px solid ${LIGHT_CRYPTO_PALETTE.black}`,
                        "&:focus-within": {
                            borderBottomWidth: "1px",
                        },
                        width: "92%",
                        position: "absolute",
                        maxHeight: "35px",
                        bottom: "15px",
                        left: "10px",
                        color: LIGHT_CRYPTO_PALETTE.black,
                    }}
                    id="search"
                    value={searchValue}
                    disableUnderline
                    autoComplete="off"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleSearch}
                                sx={{
                                    color: LIGHT_CRYPTO_PALETTE.black,
                                    "&:hover": {
                                        backgroundColor:
                                            DARK_CRYPTO_PALETTE.violetMirage,
                                    },
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
};

export default SearchDialog;
