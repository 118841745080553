import { DARK_CRYPTO_PALETTE } from "./Colors";
import { createTheme } from "@mui/material";

const DarkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            light: DARK_CRYPTO_PALETTE.lightVioletMirage,
            main: DARK_CRYPTO_PALETTE.violetMirage,
        },
        success: {
            main: DARK_CRYPTO_PALETTE.lightEmeraldGreen,
        },
        error: {
            main: DARK_CRYPTO_PALETTE.rubyRed,
        },
        background: {
            paper: DARK_CRYPTO_PALETTE.darkCharcoal,
        },
        text: {
            primary: DARK_CRYPTO_PALETTE.crystalSilver,
            secondary: DARK_CRYPTO_PALETTE.lightCrystalSilver,
            disabled: DARK_CRYPTO_PALETTE.paleCrystalSilver,
        },
        info: {
            light: DARK_CRYPTO_PALETTE.lightLavender,
            main: DARK_CRYPTO_PALETTE.deepLavender,
            dark: DARK_CRYPTO_PALETTE.white,
        },
        grey: {
            500: DARK_CRYPTO_PALETTE.lightGray,
            600: DARK_CRYPTO_PALETTE.lessLightGray,
        },
        secondary: {
            light: DARK_CRYPTO_PALETTE.darkGray,
            main: DARK_CRYPTO_PALETTE.black,
            dark: DARK_CRYPTO_PALETTE.paleBlack,
        },
        divider: DARK_CRYPTO_PALETTE.dividerGray,
        mentor: {
            main: DARK_CRYPTO_PALETTE.winterWizard,
        },
        hyperlink: {
            main: DARK_CRYPTO_PALETTE.skyblue,
        },
        editor: DARK_CRYPTO_PALETTE.ambiance,
        courseCard: {
            main: DARK_CRYPTO_PALETTE.ceruleanBlue,
        },
        passingMark: {
            main: DARK_CRYPTO_PALETTE.azureGreen,
        },
    },
});

export default DarkTheme;
