import React, { useState, useEffect } from "react";
import { Box, InputAdornment, Button, Grid } from "@mui/material";
import {
    AppBar,
    Image,
    TextField,
    IconButton,
    Toolbar,
    MUIIconButton,
    LightModeSunIcon,
    DarkModeMoonIcon,
} from "./styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import { TopNavbarProps } from "./types";
import { useRouter } from "next/router";
import SearchDialog from "./SearchDialog";
import { useTheme } from "@mui/material/styles";
// import Notifications from "./Notifications";
import { userGlobalContext } from "../../../../pages/_app";
import { getSearchTermFromUrl } from "../../../../util/urlUtil";
import Link from "next/link";

const TopNavbar = ({
    handleMobileToggle,
    walletButton,
    loginLogoutButton,
    changeTheme,
    networkSelector,
}: TopNavbarProps) => {
    const router = useRouter();
    const {
        globalQuestionSearch,
        setGlobalQuestionSearch,
        globalUser,
        loggedIn,
    } = userGlobalContext();
    const [localQuestionSearch, setLocalQuestionSearch] =
        useState<string>(globalQuestionSearch);

    // handle state of search dialog
    const [showSearchDialog, setShowSearchDialog] = useState(false);

    const globalUserAddr = globalUser?.address;

    // Hook that returns theme object for use in function component
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const mdScreen = useMediaQuery(theme.breakpoints.down("md"));

    /**
     * Search handler called when a key is pressed on the search bar.
     * Only triggers a search when 'Enter' is pressed.
     * @param event - keydown event
     */
    const search = (event: any) => {
        setLocalQuestionSearch(event.target.value);
        if (event.key === "Enter") {
            const searchTerm: string = event.target.value;
            const previousSearchTerm = getSearchTermFromUrl();
            if (searchTerm != previousSearchTerm) {
                setGlobalQuestionSearch(searchTerm);
                router.push(`/questions?search=${searchTerm}`);
            }
        }
    };

    /**
     * Handle the changing of the global search
     * @param event - change event
     */
    const handleSearchChange = (event: any) => {
        setLocalQuestionSearch(event.target.value);
    };

    /**
     * Renders the user relevant elements
     * @returns -JSX elements
     */
    const showUserCredentials = () => {
        return (
            <>
                {loginLogoutButton}
                {networkSelector}

                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "20px",
                    }}
                >
                    {walletButton}
                </Grid>
            </>
        );
    };

    useEffect(() => {
        //If we are not dealing with viewing questions then the search param is not important
        setLocalQuestionSearch(globalQuestionSearch);
    }, [router, globalQuestionSearch]);

    // close search dialog when router changes
    useEffect(() => {
        if (showSearchDialog) {
            setShowSearchDialog(false);
        }
    }, [router]);

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar position="fixed">
                <Toolbar
                    sx={
                        mdScreen
                            ? { justifyContent: "space-between" }
                            : { paddingLeft: "12px", paddingRight: "12px" }
                    }
                >
                    <IconButton
                        sx={{
                            display: mdScreen ? "inline-block" : "none",
                            color: theme.palette.text.primary,
                        }}
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleMobileToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link href="/">
                        <a>
                            <Image
                                alt="logo"
                                src={
                                    theme.palette.mode === "dark"
                                        ? "/images/Logo-Dark.png"
                                        : "/images/Logo.png"
                                }
                            />
                        </a>
                    </Link>
                    {mdScreen ? (
                        <IconButton onClick={() => setShowSearchDialog(true)}>
                            <SearchIcon fontSize="medium" />
                        </IconButton>
                    ) : (
                        <TextField
                            fullWidth
                            onChange={handleSearchChange}
                            onKeyDown={search}
                            value={localQuestionSearch || ""}
                            placeholder="Search..."
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    {!smallScreen ? (
                        <MUIIconButton onClick={changeTheme}>
                            {theme.palette.mode === "light" ? (
                                <LightModeSunIcon />
                            ) : (
                                <DarkModeMoonIcon />
                            )}
                        </MUIIconButton>
                    ) : null}
                    {loggedIn && globalUserAddr && !smallScreen
                        ? null //TODO: figure out why switching this null to {} causes crash when logging in
                        : null}
                    {!smallScreen ? showUserCredentials() : null}
                </Toolbar>
            </AppBar>
            <SearchDialog
                search={search}
                showDialog={showSearchDialog}
                setShowDialog={setShowSearchDialog}
            />
        </Box>
    );
};

export default TopNavbar;
