export type Response =
    | "SUCCESS"
    | "NOT_LOGGED_IN"
    | "SIGNATURE_VERIFICATION_FAILED"
    | "USER_DOES_NOT_EXIST"
    | "ERROR";

export function isSuccess(response: Response) {
    return response === "SUCCESS";
}
