import { User } from "@js/components/UserProfile/types";

export function maskAddress(addr: string) {
    if (addr && addr.length > 12) {
        let firstPart: string = addr.substring(0, 6);
        let lastPart: string = addr.substring(addr.length - 4, addr.length);
        return firstPart + "..." + lastPart;
    }
    return addr;
}

/**
 * Returns the display name of the user. Order precedent is: alias, ens, address
 * @param user
 * @returns user's display name
 */
export function getUserDisplayName(user: User): string {
    if (!user) return "";

    if (user.alias) return user.alias;

    if (user.ens) return user.ens;

    return user.address;
}

/**
 * Returns the alias name of the user provided.
 * @param user
 * @returns user's alias name
 */
export function getUserAliasName(user: User): string {
    if (!user) return "";

    if (user.alias) return user.alias;
    return "";
}

/**
 * Returns the alias name of the user provided.
 * @param user
 * @returns user's alias name
 */
export function getUserAboutMe(user: User): string {
    if (!user) return "";

    if (user.aboutMe) return user.aboutMe;
    return "";
}

/**
 * Returns the abbreviated/masked address if there is no display name
 * @param user
 * @returns display name of the user, abbreviated if necessary
 */
export function getUserDisplayNameAbbrev(user: User): string {
    const displayName = getUserDisplayName(user);
    // if there is no display name other than the address, return the masked address
    if (displayName === user.address) return maskAddress(user.address);

    return displayName;
}

/**
 * Get the url redirect to get to the user's profile page
 * @param user - user object
 * @returns a string that is the url to redirect to
 */
export function getUserProfileUrl(user: User): string {
    return `/user/${user.address}`;
}
