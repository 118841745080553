import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import SideNavbar from "./Navigation/SideNavbar";
import TopNavbar from "./Navigation/TopNavbar";
import { AppContainerProps } from "./types";

const AppContainer = ({
    walletButton,
    loginLogoutButton,
    children,
    changeTheme,
    networkSelector,
}: AppContainerProps) => {
    const router = useRouter();
    // mobileOpen is a boolean value that trigger mobile side nav bar to open
    const [mobileOpen, setMobileOpen] = useState(false);
    const [desktopOpen, setDesktopOpen] = useState(true);
    // handleDrawerToggle is a function to open and close the side nav bar
    const handleMobileToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const isLessonPage = router.asPath.includes("lesson");

    const handleDesktopToggle = () => {
        setDesktopOpen(!desktopOpen);
    };

    useEffect(() => {
        if (isLessonPage) setDesktopOpen(false);
    }, [isLessonPage]);

    return (
        <>
            <TopNavbar
                handleMobileToggle={handleMobileToggle}
                walletButton={walletButton}
                loginLogoutButton={loginLogoutButton}
                changeTheme={changeTheme}
                networkSelector={networkSelector}
            />
            <SideNavbar
                mobileOpen={mobileOpen}
                handleMobileToggle={handleMobileToggle}
                desktopOpen={desktopOpen}
                handleDesktopToggle={handleDesktopToggle}
            >
                {children}
            </SideNavbar>
        </>
    );
};

export default AppContainer;
