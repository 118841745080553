import {
    styled,
    Drawer as MuiDrawer,
    ListItemButton as MuiListItemButton,
    ListItemText as MuiListItemText,
    Button as MuiButton,
    alpha,
    Box as MuiBox,
    ButtonProps,
    Theme,
    CSSObject,
    BoxProps,
} from "@mui/material";

export const drawerWidth = 240;
export const contractedDrawerWidth = 33;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(4)} + 1px)`,
    },
});

export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    [theme.breakpoints.up("md")]: {
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    },
    [theme.breakpoints.down("md")]: {
        [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
        },
    },
}));

type ExtraButtonProps = {
    open?: boolean;
};

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => {
    return {
        color: theme.palette.secondary.light,
        ["&.Mui-selected"]: {
            backgroundColor: theme.palette.primary.light,
            ["& .MuiTypography-root"]: {
                fontWeight: "bold",
                color: theme.palette.primary.main,
            },
        },
        borderRadius: "0px 37px 37px 0px",
        paddingLeft: 0,
        minHeight: 48,
        paddingX: theme.spacing(2.5),
    };
});

export const ListItemText = styled(MuiListItemText)(({ theme }) => {
    return { textAlign: "center" };
});

export const Button = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== "open",
})<ButtonProps & ExtraButtonProps>(({ theme, open }) => {
    return {
        "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.8),
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        textTransform: "capitalize",
        width: "80%",
        alignSelf: "center",
        ...(!open && { [theme.breakpoints.up("md")]: { display: "none" } }),
    };
});

type ContainerBoxProps = {
    open: boolean;
};
export const ContainerBox = styled(MuiBox, {
    shouldForwardProp: (prop) => prop !== "open",
})<BoxProps & ContainerBoxProps>(({ theme, open }) => {
    return {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        minHeight: "100vh",
        minWidth: open
            ? `calc(100vw - ${drawerWidth}px)`
            : `calc(100vw - ${contractedDrawerWidth}px)`,
    };
});
