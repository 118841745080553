import {
    styled,
    Button as MuiButton,
    MenuItem as MuiMenuItem,
    MenuItemProps,
    Select as MuiSelect,
} from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { MuiTypography } from "./Home/styled";

export const WalletButton = styled(MuiButton)(({ theme }) => {
    const blackOrWhite =
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white;
    return {
        borderColor: theme.palette.text.primary,
        color: blackOrWhite,
        textTransform: "capitalize",
        fontWeight: 800,
        ":hover": {
            backgroundColor: "transparent",
            border: `1px solid ${blackOrWhite}`,
        },
    };
});

export const WalletImg = styled("img")(({ src }) => {
    return {
        src: src,
        marginLeft: "20px",
        marginRight: "5px",
        height: "24px",
        width: "24px",
    };
});

export const UserAddrDisplayWithIcon = styled(MuiTypography)(({ theme }) => {
    const blackOrWhite =
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white;
    return {
        color: blackOrWhite,
        paddingLeft: "15px",
        paddingRight: "15px",
        fontWeight: 800,
    };
});

export const UserAddrDisplayNoIcon = styled(MuiTypography)(({ theme }) => {
    const blackOrWhite =
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white;
    return {
        color: blackOrWhite,
        paddingLeft: "20px",
        paddingRight: "20px",
        fontWeight: 800,
    };
});

export const LogoutButton = styled(MuiButton)(({ theme }) => {
    const blackOrWhite =
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white;
    return {
        minWidth: "90px",
        borderColor: theme.palette.text.primary,
        color: blackOrWhite,
        textTransform: "capitalize",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        fontWeight: 600,
        ":hover": {
            backgroundColor: "transparent",
            border: `1px solid ${blackOrWhite}`,
        },
    };
});

export const LogoutIcon = styled(LogoutOutlinedIcon)(({ theme }) => {
    const blackOrWhite =
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white;
    return { color: blackOrWhite };
});

export const LoginButton = styled(MuiButton)(({ theme }) => {
    const blackOrWhite =
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white;
    return {
        borderColor: theme.palette.text.primary,
        color: blackOrWhite,
        textTransform: "capitalize",
        fontWeight: 600,
        ":hover": {
            backgroundColor: "transparent",
            border: `1px solid ${blackOrWhite}`,
        },
    };
});

export const NetworkSelect = styled(MuiSelect)(({ theme }) => {
    const blackOrWhite =
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white;
    return {
        borderColor: theme.palette.text.primary,
        color: blackOrWhite,
        textTransform: "capitalize",
        fontWeight: 600,
        marginLeft: "1rem",
        "&.MuiOutlinedInput-root": {
            fontSize: "14px",
        },
        ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.text.primary,
        },
    };
});

type StyledMenuItemProps = React.ComponentType<
    MenuItemProps<any, { component?: any }>
>;

export const MenuItem: StyledMenuItemProps = styled(MuiMenuItem)(
    ({ theme }) => {
        return {
            fontWeight: 600,
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
            },
        };
    }
);
