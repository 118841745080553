import {
    styled,
    Button as MuiButton,
    Container as MuiContainer,
    Typography,
    alpha,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Button = styled(MuiButton)(({ theme }) => {
    return {
        "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.8),
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        fontWeight: "bold",
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        textTransform: "capitalize",
        width: "180px",
    };
});

export const CheckIcon = styled(CheckCircleIcon)(({ theme }) => {
    return {
        color: theme.palette.primary.main,
    };
});

export const MuiTypography = styled(Typography)(({ theme }) => {
    return {
        color:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.text.primary,
    };
});

export const MuiArrowForward = styled(ArrowForwardIosIcon)(({ theme }) => {
    return {
        color: theme.palette.primary.main,
    };
});

export const Container = styled(MuiContainer)(({ theme }) => {
    return {
        paddingTop: "72px",
        paddingBottom: "104px",
        backgroundImage: "url('/images/HomeBackground.png')",
        backgroundSize: "contain", // "70% auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100%",
        marginRight: "unset",
    };
});
