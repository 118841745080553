import { ethers } from "ethers";
import { AccountInterface, typedData } from "starknet";
import { ECDSASignature } from "@js/components/Interfaces/interfaces";

export async function signMessage(
    message: string,
    signer: ethers.providers.JsonRpcSigner
): Promise<ECDSASignature> {
    const questionIdSignature = await signer.signMessage(message);
    const spl = ethers.utils.splitSignature(questionIdSignature);
    const sig: ECDSASignature = {
        signature: questionIdSignature,
        r: spl.r,
        s: spl.s,
        v: spl.v,
    };
    return sig;
}

/**
 * @param message to sign
 * @param account to sign from
 * @returns starknet signature. This is an array [r, s]
 */
export async function signMessageStarknet(
    message: string,
    account: AccountInterface | undefined | any
): Promise<string[]> {
    if (!account) return ["", ""];

    const msg = getStarknetLoginSignatureMessage(message);
    return await account.signMessage(msg);
}

/**
 * @param message to sign
 * @returns signature object in the format that starknet expects
 */
export function getStarknetLoginSignatureMessage(message: string) {
    return {
        domain: {
            name: "Triality",
            chainId: "SN_GOERLI",
            version: "1",
        },
        types: {
            StarkNetDomain: [
                { name: "name", type: "felt" },
                { name: "chainId", type: "felt" },
                { name: "version", type: "felt" },
            ],
            Message: [{ name: "message", type: "felt" }],
        },
        primaryType: "Message",
        message: {
            message: message,
        },
    };
}
