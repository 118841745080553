import {
    styled,
    Typography as MuiTypography,
    TypographyProps,
    TextField as MuiTextField,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    Box as MuiBox,
    Toolbar as MuiToolbar,
} from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

type StyledTypography = React.ComponentType<
    TypographyProps<any, { component: any }>
>;

export const AppBar = styled(MuiAppBar)(({ theme }) => {
    return {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none",
    };
});

export const Image = styled("img")(({ theme }) => {
    return {
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(5),
        },
        width: "200px",
        cursor: "pointer",
    };
});

export const Typography: StyledTypography = styled(MuiTypography)(
    ({ theme }) => {
        return {
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
            color: "black",
            fontWeight: 500,
        };
    }
);

export const TextField = styled(MuiTextField)(({ theme }) => {
    return {
        width: "50%",
        backgroundColor: theme.palette.background.paper,
    };
});

export const IconButton = styled(MuiIconButton)(({ theme }) => {
    return {
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(2),
        },
    };
});

export const MUIIconButton = styled(MuiIconButton)(({ theme }) => {
    return {
        margin: "0 16px",
        ":hover": {
            backgroundColor: "transparent",
        },
    };
});

export const SpacerBox = styled(MuiBox)(({ theme }) => {
    return {
        flexGrow: 1,
    };
});

export const Toolbar = styled(MuiToolbar)(({ theme }) => {
    return {
        background: theme.palette.background.paper,
    };
});

export const LightModeSunIcon = styled(LightModeOutlinedIcon)(({ theme }) => {
    return { color: theme.palette.common.black, fontSize: 20 };
});

export const DarkModeMoonIcon = styled(DarkModeOutlinedIcon)(({ theme }) => {
    return { color: theme.palette.common.white, fontSize: 20 };
});
