import React, { useState, useEffect } from "react";
import {
    Drawer,
    ListItemButton,
    ListItemText,
    Button,
    ContainerBox,
} from "./styled";
import { LeftNavOption, SideNavbarProps } from "./types";
import { useRouter } from "next/router";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SchoolIcon from "@mui/icons-material/School";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {
    Divider,
    IconButton,
    ListItemIcon,
    useMediaQuery,
    Box,
    Toolbar,
    List,
    useTheme,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { userGlobalContext } from "../../../../pages/_app";
import ContactUsDialog from "./ContactUsDialog";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Link from "next/link";
import Footer from "@js/components/Footer";

const SideNavbar = ({
    mobileOpen,
    handleMobileToggle,
    desktopOpen,
    handleDesktopToggle,
    children,
}: SideNavbarProps) => {
    // Hook that returns theme object for use in function component
    const theme = useTheme();
    const router = useRouter();
    const location = router.asPath;
    const { globalUser, loggedIn } = userGlobalContext();
    const globalUserAddr = globalUser?.address;
    const desktopView = useMediaQuery(theme.breakpoints.up("md"));
    // openDialog is a boolean indicating whether dialog is open or not
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const leftNavOptions: LeftNavOption[] = [
        {
            name: "Home",
            path: "/",
            getJsx: (selected: boolean) => (
                <HomeOutlinedIcon sx={getStyle(selected)} />
            ),
            exactPath: true,
        },
        {
            name: "Education",
            path: "/education",
            getJsx: (selected: boolean) => (
                <SchoolIcon sx={getStyle(selected)} />
            ),
        },
        {
            name: "Challenges",
            path: "/challenges",
            getJsx: (selected: boolean) => (
                <EmojiEventsIcon sx={getStyle(selected)} />
            ),
        },
        {
            name: "Community",
            path: "/users",
            getJsx: (selected: boolean) => (
                <PeopleOutlinedIcon sx={getStyle(selected)} />
            ),
            hidden: false, // do not need to hide this item
            dividerAbove: true, // put a divider above this item
        },
        {
            name: "Profile",
            path: `/user/${globalUserAddr}`,
            getJsx: (selected: boolean) => (
                <PersonOutlinedIcon sx={getStyle(selected)} />
            ),
            hidden: !globalUser || !globalUser?.address || !loggedIn, // whether to hide this item
            dividerAbove: false, // whether to put a divider above this item
        },
    ];

    const contactUsOption: LeftNavOption = {
        name: "Contact Us",
        path: "", // not used
        getJsx: (selected: boolean) => (
            <ContactSupportOutlinedIcon sx={getStyle(selected)} />
        ),
    };

    function getStyle(selected: boolean) {
        return {
            color: selected
                ? theme.palette.primary.main
                : theme.palette.mode === "dark"
                ? theme.palette.grey[500]
                : theme.palette.text.primary,
        };
    }

    // selectedIndex represents the selected index item on the side nav bar
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    // sets the index of the selected leftnav item
    useEffect(() => {
        let found = false;
        leftNavOptions.map((option, index) => {
            if (option.exactPath) {
                if (location === option.path) {
                    setSelectedIndex(index);
                    found = true;
                    return;
                }
            } else {
                if (location.indexOf(option.path) !== -1) {
                    setSelectedIndex(index);
                    found = true;
                    return;
                }
            }
        });
        if (!found) setSelectedIndex(-1);
    }, [location]);

    function getLeftNavItem(
        option: LeftNavOption,
        isOptionSelected: boolean,
        onClickFn?: any
    ) {
        return (
            <ListItemButton
                onClick={onClickFn}
                selected={isOptionSelected}
                disabled={desktopView ? !desktopOpen : !mobileOpen}
            >
                <ListItemIcon sx={{ alignItems: "center", minWidth: 0 }}>
                    {isOptionSelected ? (
                        <FiberManualRecordIcon
                            sx={{
                                fontSize: "8px",
                                width: "4em",
                                color: theme.palette.primary.main,
                            }}
                        />
                    ) : (
                        <FiberManualRecordIcon
                            sx={{
                                color: "transparent",
                                fontSize: "8px",
                                width: "4em",
                            }}
                        />
                    )}

                    {option.getJsx(isOptionSelected)}
                </ListItemIcon>
                <ListItemText
                    primary={option.name}
                    sx={{
                        color:
                            theme.palette.mode === "dark"
                                ? theme.palette.grey[500]
                                : theme.palette.text.primary,
                    }}
                />
            </ListItemButton>
        );
    }

    const renderDivider = (option: any) => {
        return (
            <Divider
                key={`${option.name}-divider`}
                flexItem
                textAlign="right"
                sx={{
                    [theme.breakpoints.up("md")]: {
                        "&::after": { width: ".01%" },
                        "& .MuiDivider-wrapper": {
                            pl: "calc(8px * 3.2)",
                            pr: "calc(8px * .2)",
                        },
                        justifyContent: "flex-end",
                        alignItems: "center",
                    },
                    [theme.breakpoints.down("md")]: {
                        mt: 2,
                        mb: 2,
                    },
                }}
            >
                {desktopView ? (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDesktopToggle}
                        edge="start"
                        sx={{
                            mt: ".5rem",
                            mb: ".5rem",
                            // pl: "calc(8px * 1.2)",
                            p: 0,
                            // mr: "-1px",
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.divider}`,
                            // marginRight: 5,
                        }}
                    >
                        {desktopOpen ? (
                            <KeyboardArrowLeftIcon fontSize="small" />
                        ) : (
                            <KeyboardArrowRightIcon fontSize="small" />
                        )}
                    </IconButton>
                ) : null}
            </Divider>
        );
    };

    /**
     * @returns the side navbar jsx
     */
    function sideNavList() {
        return (
            <List
                component="nav"
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {leftNavOptions.map((option, index) => {
                    const isOptionSelected = selectedIndex === index;
                    if (option.hidden) {
                        if (option.dividerAbove) return renderDivider(option);
                        return <></>;
                    }

                    return (
                        <div key={`${option.name}-${index}`}>
                            {option.hidden === false &&
                                option.dividerAbove &&
                                renderDivider(option)}
                            <Box
                                sx={{
                                    [theme.breakpoints.up("md")]: {
                                        borderRight: `1px solid ${theme.palette.divider}`,
                                        ...(desktopOpen && { width: "95%" }),
                                        ...(!desktopOpen && { width: "70%" }),
                                    },
                                }}
                            >
                                <Link href={option.path} passHref>
                                    {getLeftNavItem(option, isOptionSelected)}
                                </Link>
                            </Box>
                        </div>
                    );
                })}
                <Box
                    sx={{
                        [theme.breakpoints.up("md")]: {
                            borderRight: `1px solid ${theme.palette.divider}`,
                            flexGrow: 1,
                            ...(desktopOpen && { width: "95%" }),
                            ...(!desktopOpen && { width: "70%" }),
                        },
                    }}
                >
                    {getLeftNavItem(contactUsOption, false, () =>
                        setOpenDialog(true)
                    )}
                    <ContactUsDialog
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                    />
                </Box>
            </List>
        );
    }

    /**
     * Reusable function to show same ask question button on mobile and desktop
     * @returns ask question button
     */
    const askQuestionButton = () => {
        return (
            <Box
                sx={{
                    [theme.breakpoints.up("md")]: {
                        borderRight: `1px solid ${theme.palette.divider}`,
                        ...(desktopOpen && { width: "95%" }),
                        ...(!desktopOpen && { width: "70%" }),
                    },
                    textAlign: "center",
                }}
            >
                <Link href="/ask" passHref>
                    <Button open={desktopOpen}>Ask Question</Button>
                </Link>
            </Box>
        );
    };

    return (
        <ContainerBox open={desktopOpen}>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleMobileToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: "block", sm: "block", md: "none" },
                }}
            >
                <Toolbar />
                {sideNavList()}
            </Drawer>
            <Drawer
                variant="permanent"
                open={desktopOpen}
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                PaperProps={{ sx: { borderRight: "none" } }}
            >
                <Toolbar />
                {sideNavList()}
            </Drawer>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box component="main" flexGrow={1}>
                    <Toolbar />
                    {children}
                </Box>
                <Footer />
            </Box>
        </ContainerBox>
    );
};
export default SideNavbar;
