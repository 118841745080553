/**
 * Get value of given cookie
 * @param cookieName cookie name to get value of
 * @returns value of given cookie if it exists, otherwise empty string
 */
export function getCookieValue(cookieName: string) {
    if (typeof window === "undefined") return "";

    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        const cookieParts = cookie.split("=");
        const cName = cookieParts[0];
        const cValue = cookieParts[1];
        if (cName === cookieName) return cValue;
    }

    return "";
}

/**
 * Sets a cookie to given value
 * @param cookieName cookie to set
 * @param cookieValue value to set
 */
export function setCookieValue(cookieName: string, cookieValue: string) {
    if (typeof window === "undefined") return;

    document.cookie = `${cookieName}=${cookieValue};path=/`;
}

/**
 * Checks if cookie with given name exists and is set
 * @param cookieName cookie name to check
 * @returns whether cookie when the given name exists
 */
export function hasCookie(cookieName: string): boolean {
    return !!getCookieValue(cookieName);
}

export function getAddressFromHeaders(headers: any): string {
    if (!headers || !headers.cookie) {
        return "";
    }

    const addressString = headers.cookie.match(/address=.*;/);
    const address = addressString
        ? addressString[0].match(/0x[a-zA-Z0-9]*/)[0]
        : "";
    return address;
}
